<template>
  <v-container fluid>
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="$tc('carrouselTips', 1)"
            :loading="loading"
          ></i-toolbar>
          <v-row class="py-5">
            <v-col cols="12" sm="6">
              <carrousel-tips :value="[form]" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-tabs
                color="primary"
                v-model="activeTab"
                next-icon="fa-chevron-right"
                prev-icon="fa-chevron-left"
                centered
                show-arrows
                :icons-and-text="$vuetify.breakpoint.smAndUp"
              >
                <v-tab v-for="tab in tabs" :key="tab.id" :href="tab.route">
                  <span
                    class="mt-2"
                    v-text="$tc(tab.name, 2)"
                    v-if="$vuetify.breakpoint.smAndUp"
                  ></span>
                  <v-icon
                    :medium="$vuetify.breakpoint.smAndUp"
                    v-text="tab.icon"
                  />
                </v-tab>
                <v-tabs-items v-model="activeTab">
                  <v-tab-item value="tab-text" class="pt-3">
                    <v-row class="pb-5">
                      <v-col cols="6">
                        <v-switch
                          v-model="form.active"
                          :label="$t('active')"
                          name="active"
                          color="primary"
                          required
                        ></v-switch>
                      </v-col>
                      <v-col cols="6">
                        <v-switch
                          v-model="form.tip"
                          :label="$tc('tip', 1)"
                          name="tip"
                          color="primary"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <ValidationProvider
                          vid="title"
                          :name="$t('title')"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            name="title"
                            outlined
                            :error-messages="errors[0]"
                            v-model="form.title"
                            color="secondary"
                            :label="$tc('title', 1)"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <ValidationProvider
                          vid="msg"
                          :name="$t('message')"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-textarea
                            name="msg"
                            rows="2"
                            outlined
                            :error-messages="errors[0]"
                            v-model="form.msg"
                            color="secondary"
                            :label="$tc('message', 1)"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <span class="title grey--text font-weight-black">
                          {{ $t('title_color') }}
                        </span>
                        <v-color-picker
                          elevation="1"
                          mode="hexa"
                          canvas-height="120"
                          dot-size="18"
                          v-model="form.title_color"
                        ></v-color-picker>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <span class="title grey--text font-weight-black">
                          {{ $t('msg_color') }}
                        </span>
                        <v-color-picker
                          elevation="1"
                          mode="hexa"
                          canvas-height="120"
                          dot-size="18"
                          v-model="form.msg_color"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item value="tab-background" class="pt-3">
                    <v-row class="pb-5">
                      <v-col cols="12">
                        <ValidationProvider
                          vid="background_url"
                          :name="$t('background_url')"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            name="background_url"
                            outlined
                            :error-messages="errors[0]"
                            v-model="form.background_url"
                            color="secondary"
                            :label="$tc('background_url', 1)"
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <span class="title grey--text font-weight-black">
                          {{ $t('background_image') }}
                        </span>
                        <i-image
                          v-model="imageUrl"
                          :file.sync="form.background"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <span class="title grey--text font-weight-black">
                          {{ $t('background_color') }}
                        </span>
                        <v-color-picker
                          elevation="1"
                          mode="hexa"
                          canvas-height="120"
                          dot-size="18"
                          v-model="form.background_color"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import CarrouselTips from '../../components/carrouselTips/CarrouselTips.vue'

export default {
  components: { CarrouselTips },
  data() {
    return {
      loading: false,
      imageUrl: null,
      activeTab: 'tab-text',
      tabs: [
        {
          id: 1,
          name: 'text',
          icon: 'far fa-file-alt',
          route: '#tab-text'
        },
        {
          id: 2,
          name: 'background',
          icon: 'fa-palette',
          route: '#tab-background'
        }
      ],
      form: {
        title: '',
        msg: '',
        position: 0,
        active: true,
        tip: false,
        title_color: '#000',
        msg_color: '#000',
        background: null,
        background_img: '',
        background_color: '#fff',
        background_url: ''
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          let form_data = new FormData()
          for (var key in this.form) {
            if (this.form[key] == null || key === 'background_img') {
              continue
            }
            form_data.append(key, this.form[key])
            if (
              key == 'background' &&
              (this.form.background == null ||
                String(this.imageUrl).indexOf('http') != -1 ||
                String(this.form.background).indexOf(';base64') != -1 ||
                String(this.form.background).indexOf('http') != -1 ||
                String(this.imageUrl).indexOf('/sources/') != -1)
            ) {
              form_data.delete('background')
            }
          }
          this.form.pk === undefined
            ? await this.$api.loginCarrousel.create({ form: form_data })
            : await this.$api.loginCarrousel.edit({
                pk: this.form.pk,
                form: form_data
              })
          this.$toast.success(
            `${this.$tc('carrouselTips', 1)} ${this.$tc(
              this.form.pk !== undefined ? 'edited' : 'created',
              1
            )}`
          )
          this.$router.push({ name: 'carrouselTips' })
        } finally {
          this.loading = false
        }
      }
    },
    getCarrousel() {
      let pk = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (pk !== 'create') {
        this.$api.loginCarrousel
          .show({
            pk
          })
          .then((res) => {
            this.form = res.data
            this.imageUrl = this.form.background
            this.$route.meta.title = this.form.title
          })
          .catch(() => this.$router.push({ name: 'carrouselTips' }))
      } else {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
      }
    }
  },
  watch: {
    'form.background': {
      immediate: true,
      handler(val) {
        if (val !== '' && val !== null) {
          this.form.background_url = ''
          this.form.background_img = this.imageUrl
        }
      }
    },
    'form.background_url': {
      immediate: true,
      handler(val) {
        if (val !== '') {
          this.form.background = null
          this.form.background_img = ''
        }
      }
    }
  },
  mounted() {
    this.getCarrousel()
  }
}
</script>
